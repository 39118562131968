import React from 'react';

export const facebookIcon = () => {
  return (
    <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="22.5" cy="23" rx="22.5" ry="23" fill="#FC690C" />
      <path d="M24.3334 26.1875H27.25L28.4167 21.6875H24.3334V19.4375C24.3334 18.2787 24.3334 17.1875 26.6667 17.1875H28.4167V13.4075C28.0364 13.3591 26.6002 13.25 25.0835 13.25C21.916 13.25 19.6667 15.1141 19.6667 18.5375V21.6875H16.1667V26.1875H19.6667V35.75H24.3334V26.1875Z" fill="white" />
    </svg>

  );
};
