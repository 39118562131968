import React from 'react';

export const twitterIcon = () => {
  return (
    <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="22.5" cy="23" rx="22.5" ry="23" fill="#FC690C" />
      <path d="M31.97 18.7329C31.4539 19.45 30.8301 20.0754 30.1227 20.5852C30.1227 20.7725 30.1227 20.9598 30.1227 21.1575C30.1284 24.559 28.8241 27.8206 26.5021 30.2111C24.1802 32.6016 21.0349 33.921 17.7713 33.8736C15.8846 33.8802 14.022 33.4314 12.3295 32.5625C12.2382 32.5209 12.1794 32.4269 12.1797 32.3231V32.2087C12.1797 32.0592 12.296 31.9381 12.4393 31.9381C14.294 31.8744 16.0824 31.2046 17.5517 30.0234C15.873 29.9882 14.3626 28.9523 13.6675 27.3595C13.6324 27.2725 13.6433 27.1727 13.6963 27.0962C13.7494 27.0197 13.8368 26.9775 13.9271 26.9849C14.4373 27.0383 14.9526 26.9888 15.4448 26.8392C13.5917 26.4385 12.1993 24.8359 12 22.8745C11.9929 22.7804 12.0334 22.6893 12.1068 22.634C12.1802 22.5788 12.276 22.5674 12.3595 22.604C12.8568 22.8325 13.3936 22.9529 13.9371 22.9578C12.3133 21.8476 11.6119 19.736 12.2297 17.8172C12.2934 17.6308 12.4466 17.493 12.6329 17.4546C12.8192 17.4162 13.0112 17.4828 13.1383 17.6299C15.3295 20.0592 18.3402 21.5067 21.5357 21.6674C21.4538 21.3272 21.4135 20.9776 21.4158 20.6268C21.4457 18.7877 22.5381 17.1494 24.1817 16.4789C25.8252 15.8083 27.6945 16.2383 28.9146 17.5675C29.7462 17.4024 30.5502 17.1115 31.301 16.7038C31.356 16.668 31.4257 16.668 31.4807 16.7038C31.515 16.7611 31.515 16.8338 31.4807 16.8911C31.117 17.7585 30.5027 18.4861 29.7233 18.9723C30.4058 18.8898 31.0763 18.7221 31.7203 18.4728C31.7746 18.4343 31.8458 18.4343 31.9001 18.4728C31.9455 18.4944 31.9795 18.5358 31.9929 18.586C32.0064 18.6361 31.998 18.6899 31.97 18.7329Z" fill="white" />
    </svg>

  );
};
